import { OnJobUpdate } from '@/graphql/generated'
import { SubscriptionHandlerArg } from '@urql/vue'
import { cloneDeep, findIndex } from 'lodash'

export const useGallerySubscriptionJobUpdate = (): void => {
  const jobStore = useJobStore()
  const { jobs } = storeToRefs(jobStore)

  const handleJobUpdate: SubscriptionHandlerArg<OnJobUpdate, void> = (_, response) => {
    const { onJobUpdate } = response
    if (!onJobUpdate) return

    const jobIndex = findIndex(jobs.value, (job) => job.job_id === onJobUpdate.job_id)
    const existingJob = jobs.value[jobIndex]

    if (existingJob?.state === 'finished' || existingJob?.state === 'error') return

    const jobsClone = cloneDeep(jobs.value)

    if (jobIndex === -1) {
      jobsClone.unshift({ ...onJobUpdate })
    } else {
      jobsClone[jobIndex] = { ...jobsClone[jobIndex], ...onJobUpdate }
    }

    jobStore.setJobs(jobsClone)
  }
  const variables = computed(() => ({ stream: wsStream.value ?? '' }))
  useOnJobUpdate(
    {
      variables,
      pause: computed(() => !isUrqlClientReadySubscription.value || !wsStream.value),
    },
    handleJobUpdate,
  )
}
