<template>
  <header
    class="header z-[1001] border-b border-gray-100 py-0.5 dark:border-gray-700 md:py-px"
    ref="headerRef"
  >
    <div class="container">
      <div class="flex items-center">
        <RouterLink to="/" class="mr-6 inline-flex items-center">
          <Logo />
        </RouterLink>

        <!-- <I18nSelect class="mr-6 inline-flex border-x border-gray-100 dark:border-gray-700" /> -->

        <Switch
          v-model="isDarkTheme"
          :class="isDarkTheme ? 'bg-gray-800' : 'bg-gray-200'"
          class="relative inline-flex h-6 w-11 shrink-0 items-center rounded-full"
        >
          <span
            :class="isDarkTheme ? 'translate-x-6 bg-gray-900' : 'translate-x-1 bg-white'"
            class="inline-flex h-4 w-4 transform items-center justify-center rounded-full leading-none transition"
          >
            <SvgIcon :path="mdiWeatherNight" size="sm" v-if="isDarkTheme" />
            <SvgIcon :path="mdiWeatherSunny" size="sm" v-else />
          </span>
        </Switch>

        <ComingSoon v-if="preLaunchEnabled" :countdownEndDate="countdownEndDate" />

        <div class="-mr-2 ml-auto flex items-center">
          <!-- <MessageBox /> -->
          <template v-for="(li, idx) in menuList" :key="idx">
            <template v-if="li.to">
              <component
                :is="li.onClick ? 'span' : 'RouterLink'"
                v-if="!li.children?.length"
                :to="li.to"
                active-class="bg-blue-300 dark:bg-blue-900 text-blue-900 dark:text-blue-300 font-bold rounded-md"
                class="flex items-center px-2 py-4 text-gray-500 transition last:mr-0 hover:text-blue-900 dark:text-gray-200 dark:hover:text-blue-300 lg:mr-3"
                @click="li.onClick"
              >
                <component v-if="li.component" :is="li.component" v-bind="li.componentProps" />
                <SvgIcon v-else-if="li.icon" :path="li.icon" class="text-xl" />
                <span
                  v-if="li.label"
                  :class="[
                    li.icon && 'pl-1',
                    'text-sm font-bold',
                    `hidden ${li.label.visibleFrom || 'md'}:inline-block`,
                  ]"
                >
                  {{ li.label.caption }}
                </span>
              </component>

              <Dropdown v-else :item="li" />
            </template>

            <component v-else-if="li.component" :is="li.component"></component>

            <template v-else>
              <a
                v-if="!li.children?.length"
                href="#"
                class="flex items-center px-2 py-4 text-gray-500 transition last:mr-0 hover:text-blue-900 dark:text-gray-200 dark:hover:text-blue-300 lg:mr-3"
                @click="li.onClick"
              >
                <SvgIcon v-if="li.icon" :path="li.icon" class="text-xl" />
                <span
                  v-if="li.label"
                  :class="[
                    li.icon && 'pl-1',
                    'text-sm font-bold',
                    `hidden ${li.label.visibleFrom || 'md'}:inline-block`,
                  ]"
                >
                  {{ li.label.caption }}
                </span>
              </a>

              <Dropdown v-else :item="li" />
            </template>
          </template>
          <div v-if="user" @click="toggleDrawer" class="ml-1 cursor-pointer">
            <Avatar :label="user.email" :url="user.avatar" />
          </div>
        </div>

        <!-- <div class="-mr-2 ml-auto cursor-pointer px-2 py-4 md:hidden" @click="toggleMobile">
          <div :class="['hamburger', ui.mobileMenuActive && '_active']">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div> -->
      </div>
    </div>
  </header>

  <!-- Drawer Menu -->
  <Transition name="drawer">
    <div
      v-if="isDrawerOpen"
      class="fixed inset-0 z-[99999] bg-black bg-opacity-50"
      @click="closeDrawer"
    >
      <div
        class="absolute right-0 top-0 h-full w-64 transform bg-white py-4 shadow-lg transition-transform duration-300 ease-in-out dark:bg-gray-800"
        :class="{ 'translate-x-0': isDrawerOpen, 'translate-x-full': !isDrawerOpen }"
        @click.stop
      >
        <div class="flex h-full flex-col">
          <div class="mb-6 flex items-center justify-between px-4">
            <div class="flex items-center">
              <Avatar :label="user?.email" :url="user?.avatar" class="mr-3" />
              <div class="max-w-[130px]">
                <p class="truncate text-xs text-gray-500">{{ user?.email }}</p>
              </div>
            </div>
            <div class="flex">
              <button
                @click="switchUser"
                class="rounded p-1 text-gray-500 transition-colors duration-200 hover:bg-gray-700"
                title="Account switcher"
              >
                <SvgIcon :path="mdiSwapHorizontal" size="lg" />
              </button>
              <button
                @click="closeDrawer"
                class="rounded p-1 text-gray-500 transition-colors duration-200 hover:bg-gray-700"
              >
                <SvgIcon :path="mdiClose" size="lg" />
              </button>
            </div>
          </div>
          <nav class="flex-grow px-2">
            <ul>
              <li v-for="(item, index) in navItems" :key="index" class="mb-2">
                <component
                  :is="item.to ? 'RouterLink' : 'div'"
                  :to="item.to"
                  class="flex cursor-pointer items-center rounded px-2 py-3 transition duration-200 hover:bg-gray-100 dark:hover:bg-gray-700"
                  @click="item.action"
                >
                  <SvgIcon :path="item.icon" class="mr-2" />
                  {{ item.label }}
                  <div
                    v-if="item?.actionShortcut"
                    @click.stop="item?.actionShortcut"
                    class="z-[10] ml-2"
                  >
                    <SvgIcon :path="item?.shortcutIcon" />
                  </div>
                </component>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script setup lang="ts">
import { handleCopy } from '@/core/utils/Common'
import Avatar from '@c/Ui/Avatar.vue'
import Logo from '@c/Ui/Logo.vue'
import SvgIcon from '@c/Ui/SvgIcon.vue'
import { Switch } from '@headlessui/vue'
import {
  mdiAccount,
  mdiCashSync,
  mdiClose,
  mdiCog,
  mdiContentCopy,
  mdiCreditCard,
  mdiCreditCardCheckOutline,
  mdiCurrencyUsd,
  mdiDeveloperBoard,
  mdiDropbox,
  mdiFileDocumentOutline,
  mdiGithub,
  mdiImageAutoAdjust,
  mdiKey,
  mdiLogin,
  mdiLogout,
  mdiSquareRoundedBadgeOutline,
  mdiStar,
  mdiSwapHorizontal,
  mdiWeatherNight,
  mdiWeatherSunny,
} from '@mdi/js'
import { RouterLink } from 'vue-router'
import ComingSoon from './ComingSoonMessage.vue'
import Dropdown from './Dropdown.vue'
import MessageBox from './MessageBox.vue'

const { t } = useI18n()
const router = useRouter()
const ui = useUiStore()
const preLaunchEnabled = import.meta.env.VITE_PRE_LAUNCH_ENABLED === 'true'

const headerRef = ref<HTMLElement | null>(null)
const isDrawerOpen = ref(false)

const sessionStore = useSessionStore()
const { JWTtokens, user } = storeToRefs(sessionStore)
const { data } = useGetApiKey({
  requestPolicy: 'cache-and-network',
  variables: {},
  pause: computed(() => !JWTtokens.value),
})
const apiKey = computed(() => data?.value?.getApiKey || '')

const toggleDrawer = () => {
  isDrawerOpen.value = !isDrawerOpen.value
}

const closeDrawer = () => {
  isDrawerOpen.value = false
}

const signOut = () => {
  sessionStore.logout({ callback: () => router.replace('/') })
  closeDrawer()
}

const switchUser = async () => {
  sessionStore.logout({ autoRedirectToLogin: true })
  closeDrawer()
}

const handleCopyApiKey = () => {
  handleCopy(apiKey.value, () => {
    closeDrawer()
    ui.addToast({
      type: 'success',
      message: t('apiKey.coppySuccess'),
    })
  })
}

// menu
const uploadStore = useUploadStoreV2()
const { hasUploadingOrQueue } = storeToRefs(uploadStore)
const menuList = computed(() => {
  const list = [
    {
      to: '/gallery',
      icon: mdiImageAutoAdjust,
      label: {
        caption: t('header.menu.gallery'),
      },
      visible: !preLaunchEnabled,
    },
    {
      to: '/pricing',
      icon: mdiCurrencyUsd,
      label: {
        caption: t('header.menu.pricing'),
      },
      visible: !preLaunchEnabled && !user.value,
    },
    {
      icon: mdiCreditCardCheckOutline,
      label: {
        caption: t('Add Photos'),
      },
      visible: !preLaunchEnabled && !!user.value,
      onClick: () => {
        if (hasUploadingOrQueue.value) {
          ui.addToast({
            type: 'error',
            message: t('Upload in progress, please wait.'),
          })
        } else {
          uploadStore.setIsShowUploader(true)
        }
      },
    },
    {
      to: '/docs',
      icon: mdiDeveloperBoard,
      label: {
        caption: t('header.menu.dev'),
      },
      visible: true,
      children: [
        {
          to: '/docs',
          icon: mdiFileDocumentOutline,
          label: {
            caption: t('header.menu.docs'),
          },
          visible: true,
        },
        {
          icon: mdiSquareRoundedBadgeOutline,
          label: {
            caption: t('header.menu.software'),
          },
          visible: true,
        },
        {
          target: '_blank',
          href: 'https://github.com/starpx1',
          icon: mdiGithub,
          label: {
            caption: t('header.menu.github'),
          },
          visible: true,
        },
      ],
    },
    {
      icon: mdiLogin,
      label: {
        caption: t('header.menu.login'),
      },
      visible: !user.value,
      onClick: () => {
        const paypalButton = document.getElementById('paypal-button')
        if (paypalButton) {
          paypalButton.style.display = 'none'
        }
        ui.openLoginDialog = true
      },
    },
    {
      component: MessageBox,
      visible: user.value,
    },
    {
      to: '/auth/signup',
      icon: mdiAccount,
      label: {
        caption: t('header.menu.signup'),
      },
      visible: !user.value,
    },
  ]

  return list.filter((x) => x.visible)
})

const goToApiKey = () => {
  router.push('/profile?t=apiKey')
  closeDrawer()
}

const goToBilling = () => {
  router.push('/profile?t=payments')
  closeDrawer()
}

const navItems = ref([
  {
    to: '/profile',
    icon: mdiAccount,
    label: 'My profile',
    action: closeDrawer,
  },
  {
    icon: mdiCashSync,
    label: 'Payments',
    action: goToBilling,
  },
  {
    to: '/profile?t=dropbox',
    icon: mdiDropbox,
    label: 'Dropbox',
    action: closeDrawer,
  },
  {
    to: { path: '/gallery', query: { starred: 'true' } },
    icon: mdiStar,
    label: 'Your stars',
    action: closeDrawer,
  },
  {
    icon: mdiKey,
    label: 'Your API Key',
    action: goToApiKey,
    shortcutIcon: mdiContentCopy,
    actionShortcut: handleCopyApiKey,
  },
  {
    to: '/settings',
    icon: mdiCog,
    label: 'Settings',
    action: closeDrawer,
  },
  {
    icon: mdiLogout,
    label: 'Sign out',
    action: signOut,
  },
])

// theme
const isDarkTheme = ref<boolean>(
  ui.theme === 'dark' ||
    (!('Starpx.theme' in localStorage) &&
      window.matchMedia('(prefers-color-scheme: dark)').matches),
)

watch(
  () => isDarkTheme.value,
  (isDark) => {
    // temporarily disable transition
    document.body.classList.add('no-transition')
    setTimeout(() => {
      document.body.classList.remove('no-transition')
    }, 250)

    if (!isDark) {
      document.documentElement.classList.remove('dark')
      // localStorage.setItem('Starpx.theme', 'white')
      ui.setTheme('white')
    } else {
      document.documentElement.classList.add('dark')
      ui.setTheme('dark')
    }
  },
)

const toggleMobile = () => {
  const headerHeight = headerRef.value?.offsetHeight
  ui.setMobileMenu(!ui.mobileMenuActive)
}

const countdownEndDate = new Date('2024-07-28T00:00:00Z')
</script>

<style scoped>
.drawer-enter-active,
.drawer-leave-active {
  transition: opacity 0.3s ease;
}

.drawer-enter-from,
.drawer-leave-to {
  opacity: 0;
}

.drawer-enter-active .absolute,
.drawer-leave-active .absolute {
  transition: transform 0.3s ease;
}

.drawer-enter-from .absolute,
.drawer-leave-to .absolute {
  transform: translateX(100%);
}
</style>
