import { Client, provideClient } from '@urql/vue'
import { whenever } from '@vueuse/core'

type ApiClientContext = {
  client: ComputedRef<Client>
  clearCache: () => void
}
const key = Symbol('api-client') as InjectionKey<ApiClientContext>
export const useApiClientProvider = () => {
  const uiStore = useUiStore()
  whenever(urqlClientSubscriptionError, (error) => {
    uiStore.addToast({ message: error, type: 'error' })
  })

  const clearCache = () => createNewUrqlClient()
  provideClient(urqlClient)

  // NOTE: clear cache when logging out
  const sessionStore = useSessionStore()
  const { user } = storeToRefs(sessionStore)
  watch(user, (val) => !val && clearCache())

  const context: ApiClientContext = {
    client: computed(() => urqlClient.value),
    clearCache,
  }
  provide(key, context)
}

export const useApiClient = () => {
  const context = inject(key)
  if (!context) {
    throw new Error('useApiClient must be inside useApiClientProvider')
  }

  return context
}
