import { isString, omit, orderBy, sum } from 'lodash'

export enum FileStatus {
  Pending = 'pending',
  Queueing = 'queueing',
  Uploading = 'uploading',
  Pausing = 'pausing',
  Cancelled = 'cancelled',
  Failed = 'failed',
  Completed = 'completed',
  Notifying = 'notifying',
  Notified = 'notified',
}

export interface FileInfo {
  fuid: string // file id FE generate
  file?: File
  name: string
  size?: number
  folder: string
  type: 'image' | 'archive' | 'imagelike'
  created_at: number
  preview_url?: string

  sid?: string // session id BE generate
  endpoint?: number
  error?:
    | 'network'
    | 'expired'
    | 'busy'
    | 'upload_failed'
    | 'rate_limit'
    | 'max_retries_reached'
    | 'access_token_issue'
    | 'notify_failed'
    | (string & {})
  hash?: string

  bytes_uploaded?: number | string // have to json stringify when it's an array
  start_uploaded_at?: number // timestamp
  is_slow_network?: boolean
  network_speed?: number

  status: FileStatus

  shasum?: string[] // backblaze logic

  token?: string
  token_expiry?: number
  upload_url?: string
}

export interface IFileStorage {
  init(): Promise<FileInfo[]>
  saveFiles(files: FileInfo[]): void
  updateFile(file: FileInfo): void
  getFiles(): FileInfo[]
  getFile(fileId: string): FileInfo
  getFileContent(fileId: string): Blob
  removeFile(fileId: string): void
}

export const getBytesUploaded = (bytesUploaded: number | string | undefined): number => {
  if (!bytesUploaded) {
    return 0
  }
  try {
    return isString(bytesUploaded) ? sum(JSON.parse(bytesUploaded)) : bytesUploaded
  } catch (e) {
    return 0
  }
}

export const sortFiles = (files: FileInfo[]): FileInfo[] => {
  return orderBy(files, ['created_at'], ['desc'])
}

export class FileStorage implements IFileStorage {
  private db: IDBDatabase
  private readonly STORE_NAME = 'files'
  private fileList: FileInfo[] = []

  constructor(
    db: IDBDatabase,
    private onChange?: (fileList: FileInfo[]) => void,
  ) {
    this.db = db
  }

  async init(): Promise<FileInfo[]> {
    this.fileList = await this._getFiles()
    return this.fileList
  }

  saveFiles(files: FileInfo[]): void {
    this.fileList.push(...files)
    this.onChange?.(this.fileList)
    this._saveFiles(files)
  }

  getFiles(): FileInfo[] {
    return this.fileList
  }

  getFile(fileId: string): FileInfo {
    const file = this.fileList.find((file) => file.fuid === fileId)
    if (!file) {
      throw new Error('File not found')
    }
    return file
  }

  getFileContent(fileId: string): Blob {
    const file = this.getFile(fileId)
    return file.file ?? ({} as Blob)
  }

  updateFile(file: FileInfo): void {
    const fileIndex = this.fileList.findIndex((f) => f.fuid === file.fuid)
    if (fileIndex === -1) {
      throw new Error('File not found')
    }
    this.fileList[fileIndex] = file
    this.onChange?.(this.fileList)
    this._updateFile(file)
  }

  removeFile(fileId: string): void {
    const fileIndex = this.fileList.findIndex((f) => f.fuid === fileId)
    if (fileIndex === -1) {
      throw new Error('File not found')
    }
    this.fileList.splice(fileIndex, 1)
    this.onChange?.(this.fileList)
    this._removeFile(fileId)
  }

  private _saveFiles(files: FileInfo[]): Promise<void> {
    const transaction = this.db.transaction(this.STORE_NAME, 'readwrite')
    const objectStore = transaction.objectStore(this.STORE_NAME)

    for (const file of files) {
      objectStore.add(omit(file, 'file'))
    }

    return new Promise((resolve, reject) => {
      transaction.oncomplete = () => resolve()
      transaction.onerror = () => reject(transaction.error)
    })
  }

  private _getFiles(): Promise<FileInfo[]> {
    const transaction = this.db.transaction(this.STORE_NAME, 'readonly')
    const objectStore = transaction.objectStore(this.STORE_NAME)
    const request = objectStore.getAll()

    return new Promise((resolve, reject) => {
      request.onsuccess = () => resolve(request.result)
      request.onerror = () => reject(request.error)
    })
  }

  private _updateFile(file: FileInfo): Promise<void> {
    const transaction = this.db.transaction(this.STORE_NAME, 'readwrite')
    const objectStore = transaction.objectStore(this.STORE_NAME)
    const request = objectStore.put(omit(file, 'file'))

    return new Promise((resolve, reject) => {
      request.onsuccess = () => resolve()
      request.onerror = () => reject(request.error)
    })
  }

  private _removeFile(fileId: string): Promise<void> {
    const transaction = this.db.transaction(this.STORE_NAME, 'readwrite')
    const objectStore = transaction.objectStore(this.STORE_NAME)
    const request = objectStore.delete(fileId)

    return new Promise((resolve, reject) => {
      request.onsuccess = () => resolve()
      request.onerror = () => reject(request.error)
    })
  }
}

export function initDatabase(dbName: string, version: number): Promise<IDBDatabase> {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(dbName, version)

    request.onerror = (event) => {
      reject('Database error: ' + (event.target as IDBOpenDBRequest).error)
    }

    request.onsuccess = (event) => {
      resolve((event.target as IDBOpenDBRequest).result)
    }

    request.onupgradeneeded = (event) => {
      const db = (event.target as IDBOpenDBRequest).result
      const objectStore = db.createObjectStore('files', { keyPath: 'fuid' })

      // Thêm các index
      objectStore.createIndex('name', 'name', { unique: false })
      objectStore.createIndex('status', 'status', { unique: false })
    }
  })
}
