import { GetJobs } from '@/graphql/generated'
import { filter, isNull } from 'lodash'
import { acceptHMRUpdate, defineStore } from 'pinia'

type Job = NonNullable<NonNullable<GetJobs['getJobs']>[number]>

export const useJobStore = defineStore('job', {
  state: () => {
    const jobs = ref<Job[]>([])
    const setJobs = (value: NonNullable<GetJobs['getJobs']>): void => {
      jobs.value = filter(value, (job) => !isNull(job))
    }

    return {
      jobs,
      setJobs,
    }
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useJobStore, import.meta.hot))
}
