<template>
  <!-- <div
    class="pointer-events-none flex justify-center opacity-0 transition delay-[6s] duration-1000"
    :class="animationReady && 'pointer-events-auto opacity-100'"
  > -->
  <div
    id="scrollToTop"
    class="fixed bottom-24 right-20 z-[9999] flex cursor-pointer justify-center"
  >
    <div
      class="inline-flex h-12 w-12 cursor-pointer items-center justify-center rounded-full bg-gray-200 transition-opacity dark:bg-gray-800"
      :class="scrolled ? 'opacity-100' : 'opacity-0'"
      @click="scrollToTop"
    >
      <SvgIcon :path="mdiArrowUp" size="xl" />
    </div>
  </div>
</template>

<script setup lang="ts">
import SvgIcon from '../SvgIcon.vue'
import { mdiArrowUp } from '@mdi/js'

const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
}

const scrolled = ref<boolean>(window.scrollY > 0)

window.addEventListener('scroll', () => (scrolled.value = window.scrollY > 0))
</script>
