import { FileInfo } from '@/core/utils/storage.idbdatabase'
import {
  FileUploadComplete,
  NotifyFileUploadComplete,
  NotifyFileUploadCompleteVariables,
} from '@/graphql/generated'
import { CombinedError } from '@urql/vue'
import { DebouncedFunc, first, throttle } from 'lodash'

const UPLOAD_NOTIFY_THROTTLE = import.meta.env.VITE_UPLOAD_NOTIFY_THROTTLE || 5000

const getFilesBySid = (files: FileInfo[], sid: string | undefined) =>
  files.filter((f) => f.sid === sid)

type UseNotificationReturnType = {
  /**
   * Throttled version of notification handler to prevent too many requests
   */
  throttledNotify: DebouncedFunc<() => Promise<void>>
  /**
   * Handles batch of completed files for notification
   */
  handleNotifyCompleteBatch: (completedFiles: FileInfo[]) => Promise<void>
}

/**
 * Hook to manage file upload completion notifications
 * @param uploadStore Store managing the upload files state
 * @returns {UseNotificationReturnType} Object containing throttled notification function
 */
export const useNotification = (
  uploadStore: ReturnType<typeof useUploadStoreV2>,
): UseNotificationReturnType => {
  const { files, completedFiles } = storeToRefs(uploadStore)
  const { client } = useApiClient()

  /**
   * Sends notification to backend about completed file uploads
   * @param files Array of completed file information
   */
  const notifyFileUploadComplete = (files: FileUploadComplete[]) => {
    return client.value.query<NotifyFileUploadComplete, NotifyFileUploadCompleteVariables>(
      NotifyFileUploadCompleteDocument,
      { files },
      { requestPolicy: 'network-only' },
    )
  }

  /**
   * Logs successful notification to Sentry
   * @param sid Upload session ID
   * @param fuids String of file IDs
   * @param totalFiles Total number of files in session
   */
  const logSuccessToSentry = (sid: string | undefined, fuids: string, totalFiles: number) => {
    const notifiedFiles = files.value.filter(
      (f) => f.sid === sid && f.status === FileStatus.Notified,
    ).length

    $sentry.setTag('uploadsession_complete', sid)
    $sentry.forceCaptureMessage(
      `[notifyFileUploadComplete] success ${notifiedFiles}/${totalFiles}: ${sid}`,
      {
        extra: {
          files: getFilesBySid(files.value, sid).map((f) => `${f.fuid}:${f.status}`),
        },
        fingerprint: [
          `uploadsession_complete_${sid}`,
          `uploadsession_complete_${new Date().toISOString()}`,
        ],
      },
    )
  }

  /**
   * Logs failed notification to Sentry
   * @param sid Upload session ID
   * @param fuids String of file IDs
   * @param totalFiles Total number of files in session
   * @param error Error information
   */
  const logFailureToSentry = (
    sid: string | undefined,
    fuids: string,
    totalFiles: number,
    error: CombinedError | string | null | undefined,
  ) => {
    const failedFiles = files.value.filter(
      (f) => f.sid === sid && f.status === FileStatus.Failed && f.error === 'notify_failed',
    ).length

    $sentry.forceCaptureException(
      `[notifyFileUploadComplete] failed ${failedFiles}/${totalFiles}: ${sid}`,
      {
        extra: {
          extra: {
            files: getFilesBySid(files.value, sid).map((f) => `${f.fuid}:${f.status}`),
          },
          error,
        },
        fingerprint: [
          `uploadsession_complete_${sid}`,
          `uploadsession_complete_${new Date().toISOString()}`,
        ],
      },
    )
  }

  /**
   * Processes a batch of completed files for notification
   * - Maps files to required format
   * - Updates file statuses during notification
   * - Handles success/failure scenarios
   * @param completedFiles Array of completed files to process
   */
  const handleNotifyCompleteBatch = async (completedFiles: FileInfo[]) => {
    if (!completedFiles.length) return

    const filesForNotification = completedFiles.map((f) => ({
      fuid: f.fuid,
      shasum: f.shasum,
      sid: f.sid!,
    }))

    if (!filesForNotification.length) return
    console.log(
      'NOTIFY[',
      filesForNotification.length,
      ']:',
      filesForNotification.map((item) => item.fuid).join(' '),
    )

    const fuids = filesForNotification.map((x) => x.fuid)
    let filesToUpdate = files.value.filter((f) => fuids.includes(f.fuid))

    // Update files to Notifying status
    filesToUpdate.forEach((f) => uploadStore.update({ ...f, status: FileStatus.Notifying }))

    // Set Sentry tag and notify backend
    $sentry.setTag(
      'upload_complete',
      filesForNotification.map((x) => `${x.sid}:${x.fuid}`).join(' '),
    )
    const { data, error } = await notifyFileUploadComplete(filesForNotification)

    // Get latest state of files
    filesToUpdate = files.value.filter((f) => fuids.includes(f.fuid))
    const sid = first(filesToUpdate)?.sid
    const fileIdString = filesToUpdate.map((f) => f.fuid).join(' ')
    const totalSessionFiles = files.value.filter((f) => f.sid === sid).length

    // Handle notification result
    if (!data?.notifyFileUploadComplete?.error && !error) {
      filesToUpdate.forEach((f) => uploadStore.update({ ...f, status: FileStatus.Notified }))
      logSuccessToSentry(sid, fileIdString, totalSessionFiles)
    } else {
      filesToUpdate.forEach((f) =>
        uploadStore.update({ ...f, status: FileStatus.Failed, error: 'notify_failed' }),
      )
      logFailureToSentry(
        sid,
        fileIdString,
        totalSessionFiles,
        data?.notifyFileUploadComplete?.error ?? error,
      )
    }
  }

  const throttledNotify = throttle(
    () => handleNotifyCompleteBatch(completedFiles.value),
    UPLOAD_NOTIFY_THROTTLE,
    { leading: false, trailing: true },
  )

  return {
    throttledNotify,
    handleNotifyCompleteBatch,
  }
}
